'use client';

import { redirect } from 'next/navigation';
import { PropsWithChildren, useEffect } from 'react';
import { config } from '../../common/util/environment';
import { AuthenticatedWrapper } from '../../contexts/TokenContext/AuthenticatedEmployeeWrapper';

export default function AuthenticatedLayout({ children }: PropsWithChildren): React.ReactNode {
    // Redirect to the debug page if we don't have a subscription key
    useEffect(() => {
        if (!config.isProd && !config.apiSubscriptionKey) {
            redirect('/debug/test?invalidKey=true');
        }
    }, []);

    return <AuthenticatedWrapper>{children}</AuthenticatedWrapper>;
}
